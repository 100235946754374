const defaultTheme = require('tailwindcss/defaultTheme');
const plugin = require('tailwindcss/plugin');

module.exports = {
  content: ['./pages/**/*.{js,ts,jsx,tsx}', './components/**/*.{js,ts,jsx,tsx}'],
  theme: {
    screens: {
      ...defaultTheme.screens
    },
    extend: {
      fontFamily: {
        sans: ['Inter var', ...defaultTheme.fontFamily.sans]
      },
      animation: {
        bounce200: 'bounce 1s infinite 200ms',
        bounce400: 'bounce 1s infinite 400ms'
      }
    }
  },

  plugins: [
    require('@tailwindcss/forms'),
    require('@tailwindcss/aspect-ratio'),
    require('@tailwindcss/typography'),
    require('@tailwindcss/line-clamp')
    // plugin(function ({ addUtilities }) {
    //   const newUtilities = {
    //     '.safe-top': {
    //       paddingTop: 'constant(safe-area-inset-top)',
    //       paddingTop: 'env(safe-area-inset-top)'
    //     },
    //     '.safe-left': {
    //       paddingLeft: 'constant(safe-area-inset-left)',
    //       paddingLeft: 'env(safe-area-inset-left)'
    //     },
    //     '.safe-right': {
    //       paddingRight: 'constant(safe-area-inset-right)',
    //       paddingRight: 'env(safe-area-inset-right)'
    //     },
    //     '.safe-bottom': {
    //       paddingBottom: 'constant(safe-area-inset-bottom)',
    //       paddingBottom: 'env(safe-area-inset-bottom)'
    //     }
    //   };

    //   addUtilities(newUtilities);
    // })
  ]
};
