import TypesenseInstantSearchAdapter from 'typesense-instantsearch-adapter';

let searchClient;

if (!searchClient) {
  const typesenseInstantsearchAdapter = new TypesenseInstantSearchAdapter({
    server: {
      apiKey: 'WpjdObqiGqNnaJHhc6RRXHvhEc10YUrH', // Be sure to use a Search API Key
      nodes: [
        {
          host: '3uybkxti64mh1w7qp-1.a1.typesense.net', // where xxx is the ClusterID of your Typesense Cloud cluster
          port: '443',
          protocol: 'https'
        }
      ]
    },
    // The following parameters are directly passed to Typesense's search API endpoint.
    //  So you can pass any parameters supported by the search endpoint below.
    //  queryBy is required.
    additionalSearchParameters: {
      queryBy: 'title,category,summary'
    }
  });
  searchClient = typesenseInstantsearchAdapter.searchClient;
}

export default searchClient;

// {
//   "name": "workshops",
//   "fields": [
//     {
//       "name": "title",
//       "type": "string"
//     },
//     {
//       "name": "category",
//       "type": "string",
//       "facet": true,
//       "optional": true
//     },
//     {
//       "name": "summary",
//       "type": "string",
//       "optional": true
//     },
//     {
//       "name": "displayWorkshop",
//       "type": "bool",
//       "index": false,
//       "optional": true
//     },
//     {
//       "name": "displayUrl",
//       "type": "string",
//       "index": false,
//       "optional": true
//     }

//   ]
// }
