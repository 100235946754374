import PropTypes from 'prop-types';
import { tw } from 'utils';

Badge.propTypes = {
  label: PropTypes.string
};

export default function Badge({ label }) {
  return (
    <span
      className={tw(
        'inline-flex items-center',
        'px-3 py-0.5 rounded-full',
        'text-sm font-medium',
        'bg-indigo-100 text-indigo-800'
      )}
      style={{ width: 'fit-content' }}>
      <svg
        className={tw('', '-ml-1 mr-1.5', 'h-2 w-2', 'text-indigo-400')}
        fill={'currentColor'}
        viewBox={'0 0 8 8'}>
        <circle cx={4} cy={4} r={3} />
      </svg>
      {label}
    </span>
  );
}
