import { UserIcon } from '@heroicons/react/solid';
import PropTypes from 'prop-types';
import { tw } from 'utils';

UserAvatar.propTypes = {
  profilePhoto: PropTypes.string
};

export default function UserAvatar({ profilePhoto, size }) {
  return (
    <div>
      {profilePhoto ? (
        <img
          className={tw(size ? size : 'h-8 w-8', 'rounded-full')}
          src={profilePhoto}
          alt={'user profile'}
        />
      ) : (
        <UserIcon
          className={tw(
            size ? size : 'h-6 w-6',
            'h-6 w-6 bg-indigo-100 rounded-full  text-indigo-600'
          )}
        />
      )}
    </div>
  );
}
