import PropTypes from 'prop-types';
import { tw } from 'utils';
Button.propTypes = {
  size: PropTypes.string,
  type: PropTypes.string,
  text: PropTypes.string,
  onClick: PropTypes.func,
  layoutClasses: PropTypes.string,
  full: PropTypes.bool,
  style: PropTypes.object,
  submit: PropTypes.bool,
  children: PropTypes.any,
  disabled: PropTypes.bool
};

export const BUTTON_TYPES = {
  Primary: 'primary',
  Secondary: 'secondary',
  White: 'white',
  Danger: 'danger',
  DangerText: 'danger-text',
  Text: 'text',
  TextBordered: 'text-bordered',
  TextGray: 'text-gray'
};
export const BUTTON_SIZES = {
  xs: 'xs',
  sm: 'sm',
  md: 'md',
  lg: 'lg',
  xl: 'xl'
};
export default function Button({
  style = {},
  size,
  type = BUTTON_TYPES.Primary,
  text,
  onClick,
  layoutClasses = '',
  full = false,
  submit = false,
  children,
  disabled = false
}) {
  const paddings = {
    xs: 'px-2.5 py-1.5',
    sm: 'px-3 py-2',
    md: 'px-4 py-2',
    lg: 'px-4 py-2',
    xl: 'px-6 py-3'
  };
  const sizes = {
    xs: 'text-sm rounded',
    sm: 'text-sm rounded-md',
    md: 'text-sm rounded-md',
    lg: 'text-base rounded-md',
    xl: 'text-base rounded-md'
  };
  const padding = paddings?.[size] ?? paddings.md;
  const types = {
    primary: `shadow-sm text-white bg-indigo-600 border border-transparent hover:bg-indigo-700 ${padding}`,
    secondary: `border-transparent text-indigo-700 bg-white hover:bg-indigo-200 ${padding}`,
    white: `shadow-sm text-gray-700 border border-gray-300 hover:bg-gray-50 ${padding}`,
    danger: `shadow-sm text-white bg-red-600 border border-transparent hover:bg-red-700 ${padding}`,
    'danger-text': `border-transparent text-red-600 hover:text-red-700 p-1`,
    text: `border-transparent text-indigo-700 bg-transparent hover:underline p-1`,
    'text-bordered': `border-transparent text-indigo-700 bg-white hover:bg-indigo-200 border border-indigo-300 ${padding}`,
    'text-gray': `border-transparent text-gray-500 bg-white hover:text-gray-900 p-1`,
    disabled: `shadow-sm border border-transparent cursor-not-allowed bg-gray-600 text-gray-100 ${padding}`
  };
  const sizeCSS = sizes?.[size] ?? sizes.md;

  const buttonType = disabled ? 'disabled' : type;
  const typeCSS = types?.[buttonType] ?? types.primary;

  const width = full ? 'w-full' : '';

  return (
    <button
      style={style}
      disabled={disabled}
      onClick={onClick}
      type={submit ? 'submit' : 'button'}
      className={tw(
        'justify-center inline-flex items-center',
        'font-medium',
        'focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500',
        sizeCSS,
        width,
        typeCSS,
        layoutClasses
      )}>
      {text}
      {/* eslint-disable-next-line sonarjs/no-identical-expressions */}
      {children && children}
    </button>
  );
}
