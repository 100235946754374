import { SearchIcon } from '@heroicons/react/solid';
import Badge from 'components/atoms/Badge';
import searchClient from 'dbs/searchClient';
import useWorkshop from 'hooks/useWorkshop';
import { useRouter } from 'next/router';
import { useTranslation } from 'next-i18next';
import PropTypes from 'prop-types';
import { useEffect } from 'react';
import {
  connectSearchBox,
  connectStateResults,
  Highlight,
  Hits,
  InstantSearch
} from 'react-instantsearch-dom';
import { tw } from 'utils';

SearchInput.propTypes = {
  border: PropTypes.string,
  placeholder: PropTypes.string,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  displaySearchResult: PropTypes.bool,
  searchQuery: PropTypes.string
};

const StateResults = ({ searchResults }) => {
  const { setHits } = useWorkshop();
  const hits = searchResults?.hits;
  useEffect(() => {
    setHits(hits);
  }, [hits]);

  return null;
};

function SearchInputBar({
  displaySearchResult,
  border = 'border-gray-300',
  placeholder = 'Search',
  value,
  onChange,
  searchQuery
}) {
  return (
    <div className={tw('relative flex-1 flex items-center lg:ml-6')}>
      <SearchInput
        searchQuery={searchQuery}
        border={border}
        placeholder={placeholder}
        onChange={onChange}
        value={value}
      />
      {value && displaySearchResult && <SearchInputResults />}
    </div>
  );
}

function SearchInputResults() {
  const { push } = useRouter();
  return (
    <div className={tw('absolute top-14 left-0 bg-white shadow rounded max-h-80 overflow-y-auto')}>
      <Hits
        hitComponent={({ hit }) => {
          if (!hit.displayWorkshop) return null;

          return (
            <div
              className={tw('my-4 cursor-pointer px-4')}
              onClick={() => push(`w/${hit?.displayUrl}`)}>
              <p className={tw('text-md font-bold text-gray-800')}>
                <span className={tw('mr-1')}>•</span>
                <Highlight hit={hit} attribute={'title'} tagName={'mark'} />
              </p>
              <p className={tw('text-sm text-gray-400')}>
                <Highlight hit={hit} attribute={'summary'} tagName={'mark'} />
              </p>
              <span>{hit.category && <Badge label={hit.category} />}</span>
            </div>
          );
        }}
      />
    </div>
  );
}

function SearchInput({ border, placeholder, onChange, value, searchQuery }) {
  useEffect(() => {
    if (searchQuery) onChange(searchQuery);
  }, [searchQuery]);
  return (
    <div className={tw('max-w-lg w-full lg:max-w-md')}>
      <label htmlFor={'search'} className="sr-only">
        Search
      </label>
      <div className={tw('relative')}>
        <div className={tw('absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none')}>
          <SearchIcon className={tw('h-5 w-5 text-gray-400')} aria-hidden="true" />
        </div>
        <input
          id={'instant-search-input'}
          name={'search'}
          value={value}
          defaultValue={searchQuery}
          onChange={(event) => onChange(event.currentTarget.value)}
          className={tw(
            'block  leading-5  ',
            'border',
            border,
            'pl-10 pr-3 py-2',
            'sm:text-sm  rounded-md w-full focus:outline-none focus:ring-1',
            'focus:ring-indigo-500 focus:border-indigo-500 bg-white placeholder-gray-500 focus:placeholder-gray-400'
          )}
          placeholder={placeholder}
          type={'search'}
          autoComplete={'off'}
        />
      </div>
    </div>
  );
}
const SearchInputInstant = connectSearchBox(
  ({ searchQuery, currentRefinement, refine, ...rest }) => {
    return (
      <SearchInputBar
        searchQuery={searchQuery}
        value={currentRefinement}
        onChange={refine}
        {...rest}
      />
    );
  }
);
const CustomStateResults = connectStateResults(StateResults);
export default function WorkshopSearchInput({ displaySearchResult, searchQuery }) {
  const { t } = useTranslation();

  return (
    <InstantSearch searchClient={searchClient} indexName={'workshops'}>
      {/* <SearchBox /> */}
      {/* <Stats /> */}
      {!displaySearchResult && <CustomStateResults />}
      <SearchInputInstant
        searchQuery={searchQuery}
        displaySearchResult={displaySearchResult}
        border={'border-none'}
        placeholder={t('workshop.navbar.search-for-workshop')}
      />
    </InstantSearch>
  );
}
