import { Menu, Transition } from '@headlessui/react';
import PropTypes from 'prop-types';
import { Fragment, useState } from 'react';
import { tw } from 'utils';
Dropdown.propTypes = {
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      onClick: PropTypes.func
    })
  ).isRequired,
  layoutClasses: PropTypes.string,
  rounded: PropTypes.bool,
  position: PropTypes.string,
  onSelect: PropTypes.func,
  defaultValue: PropTypes.shape({
    label: PropTypes.string
  })
};

export default function Dropdown({
  children,
  options,
  position = 'right',
  rounded = false,
  layoutClasses = '',
  onSelect,
  itemClasses = '',
  defaultValue
}) {
  const [selected, setSeleted] = useState(defaultValue);
  const pos = position === 'right' ? 'right-0' : 'left-0';

  return (
    <Menu as={'div'} className={tw('relative flex-shrink-0', layoutClasses)}>
      {({ open }) => (
        <>
          <div>
            <Menu.Button
              className={tw(
                'flex',
                'focus:ring-indigo-500 bg-white text-sm focus:outline-none  focus:ring-offset-2',
                rounded && 'rounded-full focus:ring-2',
                layoutClasses
              )}>
              {() => children(selected)}
            </Menu.Button>
          </div>
          <Transition
            show={open}
            as={Fragment}
            enter={'transition ease-out duration-100'}
            enterFrom={'transform opacity-0 scale-95'}
            enterTo={'transform opacity-100 scale-100'}
            leave={'transition ease-in duration-75'}
            leaveFrom={'transform opacity-100 scale-100'}
            leaveTo={'transform opacity-0 scale-95'}>
            <Menu.Items
              static
              className={tw(
                'z-20',
                'origin-top-right absolute',
                'mt-2  py-1',
                'w-48 focus:outline-none rounded-md ring-1',
                'ring-black ring-opacity-5 bg-white shadow-lg',
                pos,
                itemClasses
              )}>
              {options.map((option, idx) => (
                <Menu.Item key={idx}>
                  {({ active }) => (
                    <a
                      href={option.label}
                      onClick={(event) => {
                        event.preventDefault();
                        setSeleted(option);
                        onSelect(option);
                      }}
                      className={tw(
                        active ? 'bg-gray-100' : '',
                        'block px-4 py-2 text-sm text-gray-700'
                      )}>
                      {option.label}
                    </a>
                  )}
                </Menu.Item>
              ))}
            </Menu.Items>
          </Transition>
        </>
      )}
    </Menu>
  );
}
